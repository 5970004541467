.hyphenate {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.syllabic-wrap {
  word-break: normal;
  overflow-wrap: anywhere;
}
