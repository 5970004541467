$background-portal:  map-get($grey, 'lighten-4'); //#f5f5f5

$material-light: (
        'status-bar': (
                'regular': map-get($grey, 'lighten-2'),
                'lights-out': rgba(map-get($shades, 'white'), 0.7)
        ),
        'app-bar': $background-portal,
        'background': $background-portal,
        'bottom-navigation': $background-portal,
        'surface': map-get($shades, 'white'),
        'calendar': (
                'background-color': map-get($shades, 'white'),
                'outside-background-color': #f7f7f7,
                'weeknumber-background-color': #f1f3f4,
                'line-color': map-get($grey, 'lighten-2'),
                'interval-color': map-get($grey, 'darken-3'),
                'interval-line-color': map-get($grey, 'lighten-2'),
                'text-color': map-get($shades, 'black'),
                'past-color': rgba(map-get($shades, 'black'), .38)
        ),
        'cards': map-get($shades, 'white'),
        'chips': #e0e0e0,
        'code': (
                'background': rgba(map-get($shades, 'black'), 0.05),
                'color': currentColor
        ),
        'kbd': (
                'background': #212529,
                'color': map-get($shades, 'white')
        ),
        'dividers': rgba(204, 204, 204, 1),
        'text': (
                'theme': map-get($shades, 'white'),
                'primary': map-get($shades, 'black'),
                'secondary': rgba(map-get($shades, 'black'), 0.6),
                'disabled': rgba(map-get($shades, 'black'), 0.38),
                'link': map-get($blue, 'darken-3'),
                'link-hover': map-get($grey, 'darken-3'),
                'info':  map-get($blue, 'base'),
                'success': map-get($green, 'base'),
                'warning': map-get($orange, 'base'),
                'error': map-get($red, 'accent-2'),
        ),
        'icons': (
                'active': rgba(map-get($shades, 'black'), 0.54),
                'inactive': rgba(map-get($shades, 'black'), 0.38)
        ),
        'inputs': (
                'box': rgba(map-get($shades, 'black'), 0.04),
                'solo-inverted': rgba(map-get($shades, 'black'), 0.06),
                'solo-inverted-focused': map-get($grey, 'darken-3'),
                'solo-inverted-focused-label': rgba(map-get($shades, 'white'), .7),
                'solo-inverted-focused-placeholder': rgba(map-get($shades, 'white'), 0.5),
                'solo-inverted-focused-text': map-get($shades, 'white')
        ),
        'buttons': (
                'disabled': rgba(map-get($shades, 'black'), 0.26),
                'focused': rgba(map-get($shades, 'black'), 0.12),
                'focused-alt': rgba(map-get($shades, 'white'), 0.6),
                'pressed': rgba(#999, 0.4)
        ),
        'expansion-panels': (
                'focus': map-get($grey, 'lighten-3')
        ),
        'navigation-drawer': $background-portal,
        'selection-controls': (
                'disabled': rgba(map-get($shades, 'black'), 0.26),
                'thumb': (
                        'inactive': map-get($shades, 'white'),
                        'disabled': map-get($grey, 'lighten-5')
                ),
                'track': (
                        'inactive': rgba(map-get($shades, 'black'), 0.38),
                        'disabled': rgba(map-get($shades, 'black'), 0.12)
                )
        ),
        'slider': (
                'active': rgba(map-get($shades, 'black'), 0.38),
                'inactive': rgba(map-get($shades, 'black'), 0.26),
                'disabled': rgba(map-get($shades, 'black'), 0.26),
                'discrete': map-get($shades, 'black')
        ),
        'skeleton': linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .3), rgba(255, 255, 255, 0)),
        'states': (
                'hover': 0.04,
                'focus': 0.12,
                'selected': 0.08,
                'activated': 0.12,
                'pressed': 0.16,
                'dragged': 0.08
        ),
        'tabs': rgb(0, 0, 0),
        'toggle-buttons': (
                'color': map-get($shades, 'black')
        ),
        'text-fields': (
                'filled': rgba(map-get($shades, 'black'), 0.06),
                'filled-hover': rgba(map-get($shades, 'black'), 0.12),
                'outlined': rgba(map-get($shades, 'black'), 0.38),
                'outlined-disabled': rgba(map-get($shades, 'black'), 0.26),
                'outlined-hover': rgba(map-get($shades, 'black'), 0.86)
        ),
        'toolbar': map-get($shades, 'white'),
        'input-bottom-line': rgba(map-get($shades, 'black'), 0.42),
        'stepper': (
                'active': rgba(map-get($shades, 'white'), 1),
                'completed': rgba(map-get($shades, 'black'), 0.87),
                'hover': rgba(map-get($shades, 'black'), 0.54)
        ),
        'table': (
                'active': map-get($grey, 'lighten-4'),
                'hover': $table-hover-background-color,
                //'hover': rgba(map-get($shades, 'transparent'), 0),
                'group': map-get($grey, 'lighten-3')
        ),
        'picker': (
                'body': $purple-portal,
                'clock': map-get($grey, 'lighten-2'),
                'indeterminateTime': map-get($grey, 'lighten-1'),
                'title': map-get($grey, 'lighten-2')
        ),
        'color-picker': (
                'checkerboard': rgba(map-get($shades, 'white'), 0)
        ),
        'bg-color': map-get($shades, 'white'),
        'fg-color': map-get($shades, 'black'),
        'text-color': map-get($shades, 'black'),
        'primary-text-percent': 0.87,
        'secondary-text-percent': 0.6,
        'disabledORhints-text-percent': 0.38,
        'divider-percent': 0.12,
        'active-icon-percent': 0.54,
        'inactive-icon-percent': 0.38
);

$material-dark: (
        'status-bar': (
                'regular': map-get($shades, 'black'),
                'lights-out': rgba(map-get($shades, 'black'), 0.2)
        ),
        'app-bar': $purple-portal,
        'background': $purple-portal,
        'bottom-navigation': map-get($material-dark-elevation-colors, '8'),
        'surface': $purple-portal,
        'calendar': (
                'background-color': #303030,
                'outside-background-color': #202020,
                'weeknumber-background-color': #202020,
                'line-color': map-get($grey, 'base'),
                'interval-color': map-get($grey, 'lighten-3'),
                'interval-line-color': map-get($grey, 'darken-2'),
                'text-color': map-get($shades, 'white'),
                'past-color': rgba(map-get($shades, 'white'), .50)
        ),
        'cards': map-get($material-dark-elevation-colors, '1'),
        'chips': $purple-portal,
        'dividers': rgba(map-get($shades, 'white'), 0.12),
        'text': (
                'theme': $purple-portal,
                'primary': map-get($shades, 'white'),
                'secondary': rgba(map-get($shades, 'white'), 0.7),
                'disabled': rgba(map-get($shades, 'white'), 0.5),
                'link': $purple-portal,
                'link-hover': map-get($grey, 'lighten-3')
        ),
        'icons': (
                'active': map-get($shades, 'white'),
                'inactive': rgba(map-get($shades, 'white'), 0.5)
        ),
        'inputs': (
                'box': map-get($shades, 'white'),
                'solo-inverted': rgba(map-get($shades, 'white'), 0.16),
                'solo-inverted-focused': map-get($shades, 'white'),
                'solo-inverted-focused-label': rgba(map-get($shades, 'black'), .6),
                'solo-inverted-focused-placeholder': rgba(map-get($shades, 'black'), 0.38),
                'solo-inverted-focused-text': rgba(map-get($shades, 'black'), 0.87)
        ),
        'buttons': (
                'disabled': rgba(map-get($shades, 'white'), 0.3),
                'focused': rgba(map-get($shades, 'white'), 0.12),
                'focused-alt': rgba(map-get($shades, 'white'), 0.1),
                'pressed': rgba(#ccc, 0.25)
        ),
        'expansion-panels': (
                'focus': #494949
        ),
        'navigation-drawer': map-get($material-dark-elevation-colors, '16'),
        'selection-controls': (
                'disabled': rgba(map-get($shades, 'white'), 0.3),
                'thumb': (
                        'inactive': map-get($grey, 'lighten-1'),
                        'disabled': map-get($grey, 'darken-3')
                ),
                'track': (
                        'inactive': rgba(map-get($shades, 'white'), 0.3),
                        'disabled': rgba(map-get($shades, 'white'), 0.1)
                )
        ),
        'slider': (
                'active': rgba(map-get($shades, 'white'), 0.3),
                'inactive': rgba(map-get($shades, 'white'), 0.2),
                'disabled': rgba(map-get($shades, 'white'), 0.2),
                'discrete': map-get($shades, 'white')
        ),
        'skeleton': linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .05), rgba(255, 255, 255, 0)),
        'states': (
                'hover': 0.08,
                'focus': 0.24,
                'selected': 0.16,
                'activated': 0.24,
                'pressed': 0.32,
                'dragged': 0.16
        ),
        'tabs': rgba(map-get($shades, 'white'), 0.6),
        'toggle-buttons': (
                'color': map-get($shades, 'white')
        ),
        'text-fields': (
                'filled': rgba(map-get($shades, 'white'), 0.08),
                'filled-hover': rgba(map-get($shades, 'white'), 0.16),
                'outlined': rgba(map-get($shades, 'white'), 0.24),
                'outlined-disabled': rgba(map-get($shades, 'white'), 0.16),
                'outlined-hover': map-get($shades, 'white')
        ),
        'input-bottom-line': rgba(map-get($shades, 'white'), 0.7),
        'stepper': (
                'active': rgba(map-get($shades, 'white'), 1),
                'completed': rgba(map-get($shades, 'white'), 0.87),
                'hover': rgba(map-get($shades, 'white'), 0.75)
        ),
        'table': (
                'active': #505050,
                'hover': map-get($deep-purple, 'darken-4'),
                'group': map-get($grey, 'darken-2')
        ),
        'toolbar': map-get($material-dark-elevation-colors, '4'),
        'picker': (
                'body': map-get($grey, 'darken-3'),
                'clock': map-get($grey, 'darken-2'),
                'indeterminateTime': map-get($grey, 'darken-1'),
                'title': map-get($grey, 'darken-2')
        ),
        'color-picker': (
                'checkerboard': rgba(map-get($shades, 'white'), 0.12)
        ),
        'bg-color': $purple-portal,
        'fg-color': map-get($shades, 'white'),
        'text-color': map-get($shades, 'white'),
        'primary-text-percent': 1,
        'secondary-text-percent': 0.7,
        'disabledORhints-text-percent': 0.5,
        'divider-percent': 0.12,
        'active-icon-percent': 1,
        'inactive-icon-percent': 0.5
);

$material-theme: $material-light;

//Component Specific variables.

$navigation-drawer-border-width: 0;

$card-elevation: 3;
$card-border-radius: 8px;

$toolbar-content-padding-x: 0;

//Tabs
$tab-font-size: map-deep-get($headings, 'subtitle-2', 'size') !default;

//Forms & Inputs
$text-field-outlined-fieldset-border: 1px solid map-get($blue, 'darken-3') !default;

