
.patient-avatar{
  border-bottom: 10px solid inherit;
}

.stepper-container{
  top: 0px;
  left: 0px;
  height: 1180px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  opacity: 1;
}