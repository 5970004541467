
// Preset variables
$body-font-family: 'Helvetica Neue', sans-serif;
$body-font-family-2: 'Roboto';
$font-size-root: 16px !default;


$font-size-xlarge: 25px;
$font-size-large: 18px;
$font-size-medium: $font-size-root;
$font-size-small: 12px;

$font-size-h1:         $font-size-xlarge;
$font-size-h2:         24px;
$font-size-h3:         20px;
$font-size-h4:         $font-size-large;
$font-size-h5:         $font-size-medium;
$font-size-caption:    $font-size-medium;

$font-weights: (
        'thin': 100,
        'light': 300,
        'regular': 400,
        'medium': 500,
        'bold': 700,
        'black': 900
);

$heading-font-family: $body-font-family;

$headings: (
                        'h1': (
                                'size': $font-size-h1,
                                'weight': 700,
                                'line-height': 30px,
                                'letter-spacing': 0,
                                'font-family': $heading-font-family,
                                'text-transform': false
                        ),
                        'h2': (
                                'size': $font-size-h2,
                                'weight': 700,
                                'line-height': 29px,
                                'letter-spacing': 0,
                                'font-family': $heading-font-family,
                                'text-transform': false
                        ),
                        'h3': (
                                'size':$font-size-h3,
                                'weight': 400,
                                'line-height': 42px,
                                'letter-spacing': 0,
                                'font-family': $heading-font-family,
                                'text-transform': false
                        ),
                        'h4': (
                                'size': $font-size-h4,
                                'weight': 400,
                                'line-height': 22px,
                                'letter-spacing': 0,
                                'font-family': $heading-font-family,
                                'text-transform': false
                        ),
                        'h5': (
                                'size': $font-size-h5,
                                'weight': 400,
                                'line-height': 2rem,
                                'letter-spacing': normal,
                                'font-family': $heading-font-family,
                                'text-transform': false
                        ),
                        // Card Size..
                        'h6': (
                                'size': 24px,
                                'weight': 700,
                                'line-height': 29px,
                                'letter-spacing': 0,
                                'font-family': $heading-font-family,
                                'text-transform': false
                        ),
                        'subtitle-1': (
                                'size': 1rem,
                                'weight': normal,
                                'line-height': 1.75rem,
                                'letter-spacing': .009375em,
                                'font-family': $body-font-family,
                                'text-transform': false
                        ),
                        'subtitle-2': (
                                'size': $font-size-medium,
                                'weight': 400,
                                'line-height': 18px,
                                'letter-spacing': 0,
                                'font-family': $body-font-family,
                                'text-transform': false
                        ),
                        'body-1': (
                                'size': 1rem,
                                'weight': 400,
                                'line-height': 1.5rem,
                                'letter-spacing': .03125em,
                                'font-family': $body-font-family,
                                'text-transform': false
                        ),
                        'body-2': (
                                'size': .875rem,
                                'weight': 400,
                                'line-height': 1.25rem,
                                'letter-spacing': .0178571429em,
                                'font-family': $body-font-family,
                                'text-transform': false
                        ),
                        'button': (
                                'size': .875rem,
                                'weight': 500,
                                'line-height': 2.25rem,
                                'letter-spacing': .0892857143em,
                                'font-family': $body-font-family,
                                'text-transform': uppercase
                        ),
                        'caption': (
                                'size': .75rem,
                                'weight': 400,
                                'line-height': 1.25rem,
                                'letter-spacing': .0333333333em,
                                'font-family': $body-font-family,
                                'text-transform': false
                        ),
                        'overline': (
                                'size': .75rem,
                                'weight': 500,
                                'line-height': 2rem,
                                'letter-spacing': .1666666667em,
                                'font-family': $body-font-family,
                                'text-transform': uppercase
                        )
                );




