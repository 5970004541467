.appointmentDateAndTime {
  line-height: normal;
  vertical-align: center;
  margin-top: 11px;
  padding-left: 0px;
}

.appointmentProviderAndName {
  line-height: normal;
  vertical-align: center;
  padding-left: 0px;
}

.appointmentName{
  margin-top: 12px;
}