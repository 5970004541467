.patient-document-card{

  header{
    height: 112px;

    .v-toolbar__content{
      height: 64px;
    }

    .v-toolbar__extension{
      height: 48px;
    }

  }

  .v-breadcrumbs li{
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  #sub_nav_content{
    border: none;
  }

  #evaluation{
    & > h1 {
      @extend .d-none;
    }
  }

}

@media only screen and (max-width: 600px) {
  .patient-document-card{

    .item_title._20{
      width: 100% !important
    }
    ._30{
      flex: 0 0 100% !important;
      width: 100% !important;
    }
  }
}