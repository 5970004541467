.position-relative {
  position: relative;
}

.top-minus-14 {
  top: -14px;
}

.margin-left-5 {
  margin-left: 5px;
}

.disabled-link {
  pointer-events: none;
  color: #676662 !important;
  text-decoration: none;
}