
.v-application{
  a {
    color: map-deep-get($material-theme, 'text', 'link');
  }
}

.v-card {

  &.v-sheet {
    padding: 20px 20px;
  }

}

.theme--light{
  &.v-picker__body{
    background-color: white !important;
  }
}

.v-btn{
  &.v-btn--active{
    &.v-btn--rounded{
      &.theme--light{
        &.accent{
          color: $purple-portal;
        }
      }
    }
  }
}

.v-date-picker-table__current{
  color: $purple-portal !important;
}

.v-card__title {
}

.v-tab {
  text-transform: none;
  align-items: flex-start;
  &--active,&.v-tab {
    color: map-get($purple, 'darken-4');
  }
}

.v-tabs-slider {
  background-color: map-get($purple, 'darken-4');
}

.v-data-table {
  > .v-data-table__wrapper {
    > table {
      > body {
        > tr {
          &:not(:last-child) {
            > td, > th {
              border-top: thin solid map-get($material-theme, 'dividers');

              &:not(.v-data-table__mobile-row) {
                border-bottom: thin solid map-get($material-theme, 'dividers');
              }

              &:last-child {
                border-bottom: thin solid map-get($material-theme, 'dividers');
              }
            }
          }
        }
      }
    }
  }
}

.v-data-footer {
  color: rgba(map-get($shades, 'black'), 0.8);
}

.v-data-footer__pagination {

}

.v-data-footer__select {

}

.v-data-table__empty-wrapper {
  display: none;
}

.v-alert {
  margin-top: 10px;
  margin-bottom: 10px;
}

//SurveyJS
.sv_default_css {
  &.sv_main {

    .sv_qstn {
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 0.2em;
      padding-top: 0.2em;
      overflow-y: hidden !important;

      .noUi-target.noUi-ltr.noUi-horizontal {
        margin-top: 2.5em;
      }

      .alert.alert-success[data-bind] {
        overflow-wrap: break-word;
      }
    }

    .sv_container {

        .sv_header {
          padding: 1em;
          background-color: map-get($material-theme, 'bg-color');

          .sv_header__text {
            margin-top: 5px;
            margin-bottom: 5px;

            h3 {
              font-size: map-deep-get($headings, 'h3', 'size');
              font-weight: map-deep-get($headings, 'h6', 'weight');
              letter-spacing: map-deep-get($headings, 'h3', 'letter-spacing');
              line-height: map-deep-get($headings, 'h6', 'line-height');
            }
          }
        }

        .sv_body {
          border-top: thin solid map-get($material-theme, 'dividers') !important;
          .sv_nav {
            .sv_complete_btn {
              @extend .v-btn !optional;
              @extend .v-btn--is-elevated !optional;
              @extend .v-btn--has-bg !optional;
              @extend .v-btn--rounded !optional;
              @extend .theme--dark !optional;
              @extend .v-size--default !optional;
              background-color: map-get($blue, 'darken-4');
            }
          }
        }

    }
  }
}

//Text Colors
@each $text_type, $text_color in map-get($material-theme, 'text') {
  .v-application .#{$text_type} {
    @include background-color($text_color);
  }

  .v-application .#{$text_type}--text {
    @include text-color($text_color);
  }
}
