#dashboard-future-appointments {
  tr:hover {
    background-color: white;
  }

  td {
    padding: 0px;
  }
}

.calendar-links {
  a {
    text-decoration: none;
    color: black;

    &:before {
      display: inline-block;
      width: 1.25rem; 
      height: 1.25rem;
      content:"";
      vertical-align: text-bottom;
      margin-right: 0.5rem;
    }
  }
}

a#google {
  &:before {
    background-image: url('components/portal/appointments/google-icon.svg');
  }
}

a#yahoo {
  &:before {
    background-image: url('components/portal/appointments/yahoo-icon.svg');
  }
}

a#office365 {
  &:before {
    background-image: url('components/portal/appointments/office365-icon.svg');
  }
}

a#outlook {
  &:before {
    background-image: url('components/portal/appointments/outlook-icon.svg');
  }
}

a#other {
  &:before {
    background-image: url('components/portal/appointments/other-icon.svg');
  }
}
