$purple-portal: #6C18C9;
$table-hover-background-color: #6200EE0D;

$purple: (
        'base': #6C18C9,
        'lighten-5': #f3e5f5,
        'lighten-4': #e1bee7,
        'lighten-3': #ce93d8,
        'lighten-2': #ba68c8,
        'lighten-1': #ab47bc,
        'darken-1': #8e24aa,
        'darken-2': #7b1fa2,
        'darken-3': #6a1b9a,
        'darken-4': $purple-portal,
        'accent-1': #ea80fc,
        'accent-2': #e040fb,
        'accent-3': #d500f9,
        'accent-4': #aa00ff
);