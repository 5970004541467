#branding{

}

.client-logo{
  height: 58px;
  width: 115px;
}

.portal-logo{

}

.portal-title{
  font-size: $font-size-h2;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0;
}

.kipu-sponsored-logo{
  height: 28px;
  width: 111px;
}

.nav-drawer-logo{
  height: 28px;
  width: 111px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .kipu-sponsored-logo{
    height: 20px;
    width: 81px;
  }
}